import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../images/logo.png";
import "../css/navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isWalletPage = location.pathname.includes('/wallet');

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.querySelector('.navbar');
      const navbarLinks = document.querySelector('.navbar-links');
      
      if (isMenuOpen && navbar && navbarLinks) {
        if (!navbar.contains(event.target) || !navbarLinks.contains(event.target)) {
          setIsMenuOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    if (path !== location.pathname) {
      // Check if we're navigating away from wallet section
      const isLeavingWallet = location.pathname.includes('/wallet') && !path.includes('/wallet');
      navigate(path);
      // Only refresh if we're leaving the wallet section
      if (isLeavingWallet) {
        window.location.reload();
      }
    }
  };

  return (
    <nav className={`navbar ${!isWalletPage ? 'show-hamburger' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <img 
            src={logo} 
            alt="logo" 
            onClick={() => handleNavigation('/quienes-somos')} 
            style={{
              cursor: 'pointer',
              width: '180px',
              height: 'auto'
            }} 
          />
        </div>
        
        <button className="hamburger-menu" onClick={toggleMenu}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        {isMenuOpen && (
          <button 
            className="close-menu-button" 
            onClick={toggleMenu}
            aria-label="Cerrar menú"
          />
        )}

        <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <button onClick={() => handleNavigation('/quienes-somos')} className="nav-link">
            Quiénes Somos
          </button>
          <button 
            onClick={() => handleNavigation('/wallet/wallet-home')} 
            className="nav-link"
          >
            Wallet
          </button>
          <button onClick={() => handleNavigation('/tarjetas')} className="nav-link">
            Tarjetas
          </button>
          <button onClick={() => handleNavigation('/remesas')} className="nav-link">
            Remesas
          </button>
          <button onClick={() => handleNavigation('/guias-tutoriales')} className="nav-link">
            Guías
          </button>
          <button onClick={() => handleNavigation('/contacto')} className="nav-link">
            Contáctanos
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 